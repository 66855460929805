import React, { useEffect, useState } from "react"
import { Form, Input, Button, Checkbox, Row, Col } from "antd"
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons"

import "./styles.scss"
import { Link } from "gatsby"

const LoginForm = ({ onSubmit, onFailure }) => {
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)

    if (onSubmit) await onSubmit(values)

    setLoading(false)
  }

  const onFinishFailed = errorInfo => {
    onFailure && onFailure(errorInfo)
  }

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        name="username"
        rules={[{ required: true, message: "Please input your username!" }]}
      >
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Username"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item shouldUpdate={true}>
        {() => (
          <Row>
            <Col flex="50%">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading && <LoadingOutlined />} Log in
              </Button>
            </Col>
            {/*<Col flex="50%"><Link to="/account/register">Create account</Link></Col>*/}
          </Row>
        )}
      </Form.Item>
    </Form>
  )
}

export default LoginForm
