import React, { useEffect, useState } from "react"
import { Table, Tag, Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { navigate } from "gatsby"
import { getUserPosts } from "../../store/actions/user"
import PostsTable from "../../components/account/postsTable"
import { PlusOutlined } from "@ant-design/icons"
import Button from "antd/es/button"

import "./style.scss"

const AccountPage = () => {
  const [loading, isLoading] = useState(false)
  const { loggedIn, id, displayName, posts = [] } = useSelector(state => state.user)
  const dispatch = useDispatch()

  if (!id || !loggedIn) {
    navigate("/account/login", { replace: true })
  }

  useEffect(() => {
    (async () => {
      isLoading(true)
      await dispatch(getUserPosts())
      isLoading(false)
    })()
  }, [])

  const dataPosts = posts.map((post, index) => ({
    ...post,
    title: { status: post.status, title: post.title, slug: post.slug },
    key: index,
    adsClick: 0,
    earning: 0,
    edit: "/account/posts/edit/" + post.id
  }))

  return (
    <Layout name="account">
      <SEO
        title="Account page"
        description="User Account Page"
      />
      <h1> Hi {displayName}! </h1>
      <div className="postTableTitle">
         <span className="title">
           {dataPosts.length > 0 ? "Here are you current posts:" : "You didn't post anything yet, start now!"}
      </span>
        <Button type="primary" shape="round" icon={<PlusOutlined/>} size="medium"
                onClick={() => navigate("/account/posts/new")}>
          Add new post
        </Button>
      </div>

      {dataPosts.length > 0 && <PostsTable data={dataPosts} loading={loading}/>}

    </Layout>
  )
}
export default AccountPage