import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import LoginForm from "../components/login/LoginForm"
import { loginUser } from "../store/actions/user"
import { Alert } from "antd"

const LoginPage = ({ location }) => {
  const { state = {} } = location
  const user = useSelector(state => state.user)
  const { loggedIn, id, username, password } = user
  const dispatch = useDispatch()
  const [loginMsg, setLoginMsg] = useState(null)

  const doLogin = async ({ username, password }) => await dispatch(loginUser({ username, password }))

  useEffect(() => {
    if (id) {
      if (!loggedIn && username && password)
        doLogin({ username, password })
      else if (state && state.callback)
        navigate(state.callback.pathname + state.callback.search)
      else
        navigate("/account")
    }

  }, [user])

  const onSubmit = async (values) => {
    const { username, password } = values
    const result = await doLogin({ username, password })

    if (!result) {
      setLoginMsg("Something went wrong, please check your email & password")
    }

  }

  return (
    <Layout name="login">
      <SEO
        title="Login page"
        description="User Login Page"
      />
      {loginMsg && <Alert message={loginMsg} type="error" showIcon/>}
      <LoginForm onSubmit={onSubmit}/>
    </Layout>
  )
}
export default LoginPage