import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "@reach/router"
import { Skeleton } from "antd"
import { navigate } from "@reach/router"
import Button from "antd/es/button"
import Alert from "antd/es/alert"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { setPost } from "../../store/actions/editor"
import { createPost, getPostById, updatePost } from "../../store/actions/posts"
import PostComponentList from "../../components/post"
import { uploadImages, validateComponents } from "./content-helper"

import "./style.scss"
import {
  getImageBase64,
  imageUploadValidator,
} from "../../components/post/components/util/helper"

const EditPage = ({ location = {} }) => {
  const { state = {} } = location
  const { id: postId = null } = useParams()
  const { post, components, componentMethods } = useSelector(
    state => state.editor
  )
  const [loading, setLoading] = useState(true)
  const [info, setInfo] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [ErrorCmp, setErrorCmp] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    let forceReload = false

    if (state.message) {
      setInfo(<Alert message={state.message} type="success" showIcon />)
      setTimeout(() => setInfo(null), 5000)
      forceReload = true
    }

    if (postId) {
      ;(async () => {
        setLoading(true)

        if (
          !forceReload &&
          post &&
          post.id === parseInt(postId) &&
          components.length !== 0
        ) {
          // Already load that post
          return setLoading(false)
        }

        // Load the post
        const _post = await dispatch(getPostById(postId))

        if (_post.fail) {
          showError(_post.fail || "Couldn't load post data")
        } else if (_post && _post.id) {
          setPostContent(_post)
          setLoading(false)
        }
      })()
    } else {
      if (post || components.length === 0) setPostContent(null)
      setLoading(false)
    }
  }, [])

  const setPostContent = post => dispatch(setPost(post))

  const getSaveButton = () => (
    <Button
      type="primary"
      loading={submitLoading}
      disabled={loading || submitLoading}
      onClick={submitPost}
    >
      {postId ? "Update" : "Publish"} post
    </Button>
  )

  const showError = (errorMsg = "Something went wrong") =>
    setErrorCmp(
      <Alert
        message={errorMsg}
        type="error"
        closable
        afterClose={() => setErrorCmp(null)}
      />
    )

  const submitPost = async () => {
    if (components.length < 4) {
      return showError("Please add more content to your post")
    }

    setSubmitLoading(true)

    const isContentValid = validateComponents(componentMethods)

    if (isContentValid) {
      const uploadState = await uploadImages(components, componentMethods)
      console.log("UPLOAD RESULT", uploadState)
      if (uploadState.includes(false)) {
        setSubmitLoading(false)
        return showError(
          "Some of the requested images were unable to be uploaded, please try again."
        )
      }

      const postReturn = await dispatch(postId ? updatePost() : createPost())

      if (!postReturn.id || postReturn.fail || postReturn.error) {
        setSubmitLoading(false)
        return showError(
          "Something went wrong, don't worry, your draft is saved on your browser cache"
        )
      }

      await navigate("/account/posts/edit/" + postReturn.id, {
        replace: true,
        state: {
          message: `Post successful ${postId ? "updated" : "created"}.`,
        },
      })
    }
    setSubmitLoading(false)
  }

  return (
    <Layout name="post">
      <SEO title="New Post page" description="New Post Page" />

      {loading ? (
        <Skeleton active />
      ) : (
        <PostComponentList
          saveButton={getSaveButton()}
          messages={[info, ErrorCmp]}
          edit={!submitLoading}
        />
      )}
    </Layout>
  )
}
export default EditPage
