import React, { useRef, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import PostHeader from "./components/postHeader"
import { getComponentStructureData } from "./components/util"
import {
  registerComponent,
  removeComponent,
  setComponentData,
  setComponentMethods,
} from "../../store/actions/editor"
import { BsFileText } from "react-icons/bs"
import { IoMdImages } from "react-icons/io"

import "./style.scss"
import MovableComponent from "./components/util/movableComponent"
import SocialCanvas from "./components/socialCanvas"
import { imageUploadValidator } from "./components/util/helper"

const PostComponentList = ({ saveButton, messages = [], edit = false }) => {
  const postRef = useRef(null)
  const { components, disabled = false } = useSelector(state => state.editor)
  const dispatch = useDispatch()
  const [header, socialPhoto, ..._components] = components || []

  const addComponent = componentType => {
    if (!edit || disabled) return
    dispatch(
      registerComponent({
        ...getComponentStructureData(componentType),
        componentType,
      })
    )

    window.scrollTo({
      left: 0,
      top: postRef.current.scrollHeight + 40,
      behavior: "smooth",
    })
  }

  const callBackOnPaste = useCallback(onPaste)

  useEffect(() => {
    window.addEventListener("paste", callBackOnPaste)

    return () => {
      window.removeEventListener("paste", callBackOnPaste)
    }
  }, [components])

  function onPaste(event) {
    console.log(event)

    const items = event.clipboardData.items
    // find pasted image among pasted items
    let blob = null
    console.log(items)

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      if (item.type.indexOf("image") === 0) {
        blob = item.getAsFile()
      }
    }

    // load image if there is a pasted image
    if (blob !== null) {
      const reader = new FileReader()
      reader.onload = event => {
        insertImageToAvaliableComponent(blob, event.target.result) // data url!
      }

      reader.readAsDataURL(blob)
    }
  }

  const insertImageToAvaliableComponent = (file, base64) => {
    if (base64 && components) {
      components.forEach(({ component, data }) => {
        if (component === "post-image" && !data.image && !data.imageObj) {
          if (!imageUploadValidator(file)) {
            alert("Image must be a png/jpeg and not exceed 4MB")
          } else {
            _onChange(
              data.componentId,
              "regular"
            )({ imageObj: { ...file, base64 } })
          }

          return false
        }
      })
    }
  }

  const getAddComponentButton = () => {
    return (
      <div className="add-component">
        <div className="messages">{messages}</div>
        <div className="bottom-buttons">
          <div>
            <span> Add to post: </span>
            <ul>
              <li onClick={() => addComponent("text")}>
                <BsFileText disabled={!edit} />
              </li>
              <li onClick={() => addComponent("post-image")}>
                <IoMdImages disabled={!edit} />
              </li>
            </ul>
          </div>
          <div>{saveButton}</div>
        </div>
      </div>
    )
  }

  const _onChange = (componentId, type = "regular") => _data => {
    if (type === "methods")
      return dispatch(setComponentMethods(componentId, _data))
    return dispatch(setComponentData(componentId, _data))
  }

  const _remove = componentId => () => dispatch(removeComponent(componentId))

  const getComponentOnChange = componentId => ({
    onChange: _onChange(componentId),
    setMethods: _onChange(componentId, "methods"),
  })

  const getWrapperComponent = useCallback(
    ({ component: componentType, data }, index) => {
      const Component = getComponentStructureData(componentType).component
      const { componentId } = data
      const { onChange, setMethods } = getComponentOnChange(componentId)
      const remove = _remove(componentId)

      return (
        <MovableComponent
          key={componentId}
          data={data}
          remove={remove}
          disabled={disabled}
          length={_components.length}
          index={index}
        >
          <Component
            {...data}
            onChange={onChange}
            setMethods={setMethods}
            key={componentId}
            edit={!disabled && edit}
          />
        </MovableComponent>
      )
    }
  )

  const getComponents = useCallback(
    () => _components.map(getWrapperComponent),
    [_components]
  )

  const getCanvasImages = () => {
    const outputImages = []
    _components.forEach(({ component, data }) => {
      if (
        component === "post-image" &&
        ((data.imageObj && data.imageObj.base64) ||
          (data.image && data.image.url))
      ) {
        const url =
          data.imageObj && data.imageObj.base64
            ? data.imageObj.base64
            : data.image && data.image.url
            ? data.image.url
            : null

        if (url && !outputImages.includes(url)) {
          outputImages.push(url)
        }
      }
    })

    const first = outputImages[0]
    let last = null

    ;[...outputImages].reverse().forEach(url => {
      if (url !== first) {
        last = url
        return false
      }
    })

    return [first, last]
  }

  const [image1, image2] = getCanvasImages()

  useEffect(() => {
    if (socialPhoto) _onChange(socialPhoto.data.componentId)({ image1, image2 })
  }, [image1, image2])

  return (
    <div className="post" ref={postRef}>
      <PostHeader
        {...header.data}
        onChange={_onChange(header.data.componentId)}
        setMethods={_onChange(header.data.componentId, "methods")}
        edit={!disabled}
      />

      {getComponents()}

      {_components.length > 0 && image1 && (
        <SocialCanvas
          {...socialPhoto.data}
          edit={!disabled && edit}
          onChange={_onChange(socialPhoto.data.componentId)}
          setMethods={_onChange(socialPhoto.data.componentId, "methods")}
        />
      )}

      {getAddComponentButton()}
    </div>
  )
}

export default PostComponentList
