import React from "react"
import { useDispatch } from "react-redux"
import {
  AiOutlineArrowUp,
  AiOutlineArrowDown,
  AiOutlineDelete,
} from "react-icons/ai"
import {
  changeComponentOrder,
  removeComponent,
  setPost,
} from "../../../../store/actions/editor"

import "./style.scss"

const MovableComponent = ({ children, disabled, index, remove, length }) => {
  const dispatch = useDispatch()

  const moveUp = () =>
    index > 0 ? dispatch(changeComponentOrder(index, index - 1)) : null
  const moveDown = () =>
    index < length - 1 ? dispatch(changeComponentOrder(index, index + 1)) : null

  return disabled ? (
    children
  ) : (
    <div className="moveable-container">
      <ul className="actions">
        <li onClick={moveUp} data-disable={index === 0}>
          <AiOutlineArrowUp />
        </li>
        <li onClick={moveDown} data-disable={index === length - 1}>
          <AiOutlineArrowDown />
        </li>
        <li onClick={remove}>
          <AiOutlineDelete />
        </li>
      </ul>
      <div className="content">{children}</div>
    </div>
  )
}

export default MovableComponent
