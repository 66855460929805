import React from "react"
import { Link } from "gatsby"
import { GrClear, GrClock, GrStatusGood } from "react-icons/gr"
import { Table } from "antd"
import { EditOutlined } from "@ant-design/icons"
import "./style.scss"
import { navigate } from "@reach/router"

const PostsTable = ({ data, loading = false }) => (
  <Table
    loading={loading}
    columns={[
      {
        title: "Status", dataIndex: "status", key: "status",
        render: status => {
          let icon = <GrClear/>

          if (status === "published")
            icon = <GrStatusGood/>
          else if (status === "pending")
            icon = <GrClock/>

          return (<span className="postStatus"> {icon}{status}</span>)
        }
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: ({ status, title, slug }) => status === "published" ? <Link to={`/post/${slug}`}>{title}</Link> : title
      },
      { title: "Publish Date", dataIndex: "date", key: "date" },
      {
        title: "Ads Clicks",
        dataIndex: "adsClick",
        key: "adsClick"
      },
      { title: "Earning", dataIndex: "earning", key: "earning" },
      {
        title: "Edit",
        dataIndex: "edit",
        key: "edit",
        render: (link) => <EditOutlined onClick={() => navigate(link)}/>
      }
    ]}
    dataSource={data}
  />
)

export default PostsTable