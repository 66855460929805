import React, { Component, useEffect, useState } from "react"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { setAuthClient } from "../services/user"

const PrivateRoute = ({ component: Component, location, ...rest }) => {

  const user = useSelector(state => state.user)
  const { loggedIn, jwt } = user
  const [isAuthed, setIsAuthed] = useState(false)

  useEffect(() => {
    if (!loggedIn && (location.pathname !== `/account/login` || location.pathname !== `/account/register`)) {
      navigate("/account/login")
      return null
    } else {
      (async () => {
        await setAuthClient(jwt)
        setIsAuthed(true)
      })()
    }
  }, [])

  return isAuthed ? <Component {...rest} /> : null
}
export default PrivateRoute