import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from "../private-pages/privateRoute"
import AccountPage from "../private-pages/account"
import EditPage from "../private-pages/editPage"
import LoginPage from "../private-pages/login"
import RegisterPage from "../private-pages/register"
const AccountRoute = () => {
  return (
    <Router basepath="/account">
      <PrivateRoute path="/" component={AccountPage} />
      <PrivateRoute path="/posts/new" component={EditPage} />
      <PrivateRoute path="/posts/edit/:id" component={EditPage} />
      <LoginPage path="/login" />
      {/*<RegisterPage path="/register" />*/}
    </Router>
  )
}
export default AccountRoute
